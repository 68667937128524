import axios from 'axios';
import { hideGlobalLoading } from '../component/Loading';
import { message } from 'antd';


const http = axios.create({
  baseURL: window.location.origin+'/api_v1/',  // 你的API地址

  timeout: 200000,  // 请求超时时间
});

// 请求拦截器
http.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么：例如添加token
    // config.headers['Authorization'] = '你的token';
    // console.log("草泥马",url)
    config.headers["Accept-Language"] = localStorage.getItem("lang") || "en"
    if (config.url != "index/index.php" || config.url != "index/token.php" || config.url != "users/register.php") {
      config.headers["Authorization"] = localStorage.getItem("token")
      
    }
    return config;
  },
  error => {
    // 对请求错误做些什么
    console.log('err' + error);  // for debug
    return Promise.reject(error);
  }
);

// 响应拦截器
http.interceptors.response.use(
  response => {
    // 对响应数据做点什么
    const res = response.data;
    if (res.ok) {
      return res;
    } else {
      if(res.statusCode == 401){

      }else {
        message.error(res.message);

      }

      hideGlobalLoading();
      return res;
    }
  },
  error => {
    // 对响应错误做点什么
    console.log('err' + error);  // for debug
    return Promise.reject(error);
  }
);

export default http;